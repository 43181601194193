<template>
  <tr>
    <WiListDataTableActions
      :config="config"
      :props="props"
    ></WiListDataTableActions>
    <td class="wn-account__cell">
      <div class="wn-account__name-content">
        <v-avatar size="32" class="wn-account__avatar">
          <img :src="props.item.logo" :alt="props.item.name">
        </v-avatar>
        <div class="wn-account__info">
          <div class="wn-account__name">{{props.item.name}}</div>
          <div class="wn-account__description">{{props.item.description}}</div>
        </div>
      </div>
    </td>
    <td class="wn-account__cell">
      <strong>
        {{ props.item.slug }}
      </strong>
    </td>
    <td class="wn-account__cell">
      <div class="wn-account__system">
        <img 
          :src="`static/systems/${getSystemIcon(props.item.code_activation)}`" 
          :alt="props.item.code_activation"
          class="wn-account__system-icon"
        >
        {{ getSystemName(props.item.code_activation) }}
      </div>
    </td>
    <td class="wn-account__cell justify-center">
      <v-chip
        :color="getStatusColor(props.item.status)"
        text-color="white"
        small
      >
        <v-icon size="16" class="mr-1">{{ getStatusIcon(props.item.status) }}</v-icon>
        {{ getStatusText(props.item.status) }}
      </v-chip>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'Channels',
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    },
    methods: {
      getSystemIcon(code) {
        const systems = {
          'wise-control': 'wise_control.png',
          'connect-view': 'connect_view.png',
          'sis-pocos': 'sis_pocos.png',
          'wise-message': 'wise_message.png'
        }
        return systems[code] || 'wise_control.png'
      },
      getSystemName(code) {
        const systems = {
          'wise-control': 'Wise Control',
          'connect-view': 'Connect View',
          'sis-pocos': 'SisPoços',
          'wise-message': 'Wise Message'
        }
        return systems[code] || code
      },
      getStatusColor(status) {
        const colors = {
          1: 'success', // Ativo
          2: 'warning', // Teste Beta
          3: 'error',   // Bloqueado
          4: 'grey',    // Cancelado
          5: 'orange'   // Suspenso
        }
        return colors[status] || 'grey'
      },
      getStatusText(status) {
        const statusText = {
          1: 'Ativo',
          2: 'Teste Beta',
          3: 'Bloqueado',
          4: 'Cancelado',
          5: 'Suspenso'
        }
        return statusText[status] || 'Desconhecido'
      },
      getStatusIcon(status) {
        const statusIcons = {
          1: 'check_circle', // Ativo
          2: 'science',      // Teste Beta
          3: 'block',        // Bloqueado
          4: 'cancel',       // Cancelado
          5: 'warning'       // Suspenso
        }
        return statusIcons[status] || 'help'
      }
    }
  }
</script>
<style>
/* Base cell styles */
.wn-account__name-content {
  display: flex;
  align-items: center;
}

.wn-account__cell {
  padding: 0 5px !important;
  /* display: flex !important; */
  align-items: center !important;
  /* min-height: 48px; */
}

/* Avatar styles */
.wn-account__avatar {
  margin-right: 8px;
}

/* Info container styles */
.wn-account__info {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

/* Name styles */
.wn-account__name {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Description styles */
.wn-account__description {
  font-size: 15px;
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* System container styles */
.wn-account__system {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
}

/* System icon styles */
.wn-account__system-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 8px;
}
</style>